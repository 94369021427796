import CryptoJS from 'crypto-js';

const IV_SIZE = 128;
const KEY_SIZE = 256;
const _iterationCount = 1989;

class SecureHelper {
  privateKey = 'alpha22';
  constructor(privateKey) {
    this.privateKey = privateKey;
  }

  setPrivateKey = (key) => {
    this.privateKey = key;
  };

  generateKey = (salt, passPhrase) => {
    return CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
      keySize: KEY_SIZE / 32,
      iterations: _iterationCount,
    });
  };

  encryptWithIvSalt = (
    salt,
    iv,
    passPhrase,
    plainText,
  ) => {
    const key = this.generateKey(salt, passPhrase);
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  decryptWithIvSalt = (
    salt,
    iv,
    passPhrase,
    cipherText,
  ) => {
    const key = this.generateKey(salt, passPhrase);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText),
    });
    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  encryptWithServerAuth = (plainText) => {
    const iv = CryptoJS.lib.WordArray.random(IV_SIZE / 8).toString(CryptoJS.enc.Hex);
    const salt = CryptoJS.lib.WordArray.random(KEY_SIZE / 8).toString(CryptoJS.enc.Hex);
    const cipherText = this.encryptWithIvSalt(salt, iv, this.privateKey, plainText);
    return salt + iv + cipherText;
  };

  decryptWithServerAuth = (cipherText) => {
    const ivLength = IV_SIZE / 4;
    const saltLength = KEY_SIZE / 4;
    const salt = cipherText.substr(0, saltLength);
    const iv = cipherText.substr(saltLength, ivLength);
    const encrypted = cipherText.substring(ivLength + saltLength);
    const decrypted = this.decryptWithIvSalt(salt, iv, this.privateKey, encrypted);
    return decrypted;
  };

  encryptAES = (plainText) => {
    return CryptoJS.AES.encrypt(plainText, this.privateKey, {
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    }).toString();
  };

  decryptAES = (encryptedValue) => {
    if (encryptedValue) {
      return CryptoJS.AES.decrypt(encryptedValue, this.privateKey, {
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding,
      }).toString(CryptoJS.enc.Utf8);
    } else return null;
  };
}

export default new SecureHelper('');
