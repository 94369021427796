import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userInfo: null,
  isLoggingIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state) => {
      state.isLoggingIn = true;
    },
    loginSuccess: (state, action) => {
      state.isLoggingIn = false;
      state.userInfo = {...action.payload};
    },
    loginFailure: (state) => {
      state.isLoggingIn = false;
    },
    logoutAction: (state) => {
      state.userInfo = null;
    },
    logoutSuccess: (state) => {
      state.userInfo = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions;

export default authSlice.reducer;
