import {create} from 'apisauce';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {logResponseMonitor} from './extension/apiMonitors';
import {transformError, transformRequest} from './extension/apiTranforms';
import getRequestUrl from './url';
import constants from "@constants";
import { toast, Slide } from 'react-toastify'
import SecureHelper from '@src/@core/helpers/SecureHelper';

const REQUEST_TIMEOUT = 30000;

const apiInstance = (baseURL) => {
  const axiosInstance = axios.create({baseURL});

  return create({
    axiosInstance,
  });
};

class ApiClient {
  cancelToken = axios.CancelToken;
  timeExpired = null;
  requestInstance = {};
  constructor(baseURL) {
    this.instance = apiInstance(baseURL);
    this.instance.addMonitor(logResponseMonitor);
    this.instance.addRequestTransform(transformRequest);
    this.instance.addResponseTransform(transformError);
  }

  setAuthorization(tokenData) {
    const {accessToken, expiresIn} = tokenData;
    this.instance.setHeader('Authorization', `Bearer ${accessToken}`);
    this.timeExpired = new Date().getTime() + expiresIn;
  }

  initTokenCancel(requestId, token) {
    this.requestInstance[requestId] = token;
  }

  cancelRequest({requestId, url, isManual}) {
    // console.log(`cancelRequest from ${url}-${requestId}`);
    if (this.requestInstance[requestId]) {
      const cancelMessage = isManual ? 'manual' : '';
      this.requestInstance[requestId](cancelMessage);
      this.requestInstance[requestId] = null;
    }
  }

  getMethodCall = (method) => ({url, data, params, config}) => {
    if (method === 'get') {
      return this.instance.get(url, params, config);
    }

    if (method === 'post') {
      return this.instance.post(url, data, {
        ...config,
        params,
      });
    }

    if (method === 'put') {
      return this.instance.put(url, data, {
        ...config,
        params,
      });
    }

    if (method === 'delete') {
      return this.instance.delete(url, params, {
        ...config,
        data,
      });
    }

    return this.instance.get(url, params, config);
  }

  fetchApi({url, method, data, params, config = {}, suffixUrl}) {
    const {
      isAuthorization = false,
      isHandleError = true,
      requestId = `${url}-${new Date().getTime()}`,
      customHeaders = {},
      isKeepRequest = false,
      isEncrypt = false,
      isDecrypt = false,
      isFormData = false,
      isAuth = false,
      ...axiosConfig
    } = config;
    let requestUrl = getRequestUrl(url, isAuth);
    if (suffixUrl) {
      requestUrl = `${requestUrl}/${suffixUrl}`;
    }
    if(isAuthorization) {
      const tokenData = {
        accessToken: sessionStorage.getItem(constants.LOCAL_STORAGE_KEY.ACCESS_TOKEN),
        expiresIn: sessionStorage.getItem(constants.LOCAL_STORAGE_KEY.REFRESH_EXPIRE_IN)
      };
      const { exp } = jwt_decode(tokenData.accessToken)
      const expirationTime = (exp * 1000) - 60000
      if (Date.now() >= expirationTime) {
        sessionStorage.clear();
        location.reload();
      }
      this.setAuthorization(tokenData)
    }
    const cancelTimeout = isKeepRequest
      ? null
      : setTimeout(() => {
          this.cancelRequest({requestId, url});
        }, REQUEST_TIMEOUT);
    let body = isEncrypt ? {encryptData: SecureHelper.encryptWithServerAuth(JSON.stringify(data))} : {rawData: data}
    if(isFormData) {
      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
      body = formData;
    }
    const requestConfig = {
      headers: {
        clientMessageId: requestId,
        isAuthorization,
        ...customHeaders,
      },

      cancelToken: new this.cancelToken((token) => {
        this.initTokenCancel(requestId, token);
      }),
      ...axiosConfig,
    }

    return new Promise((resolve, reject) => {
      this.getMethodCall(method)({url: requestUrl, data: body, params, config: requestConfig})
        .then((response) => {
          if (response.ok) {
            // if(isDecrypt) {
            //   response.data.data = JSON.parse(AESProvider.decryptApiAuth(response.data.data))
            // }
            resolve(response.data);
          } else {
            // not auth
            if(response?.status == 401) {
              if(isAuthorization) {
                sessionStorage.clear();
                location.reload()
              }
            }
            if(response?.status == 403) {
              if(isAuthorization) {
                // window.location.assign('/misc/not-authorized');
              }
            }
            //Token Expired
            if (response.data?.errorCode === '410') {
              //LogOut
            }
            if (isHandleError && response.message) {
              //Handle Error
              console.log(response, 'response')
              // alert(response.message);
              this.handleError(response);
            }
            reject(response);
          }
        })
        .finally(() => {
          if (cancelTimeout) {
            clearTimeout(cancelTimeout);
          }
        });
    });
  }

  handleError(error) {
    toast.dismiss();
    toast.error(`${error?.data?.message || error?.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  get({url, data, config, suffixUrl}) {
    return this.fetchApi({url, params: data, config, method: 'get', suffixUrl});
  }

  post({url, data, config, suffixUrl, params}) {
    return this.fetchApi({url, data, config, method: 'post', suffixUrl, params});
  }

  put({url, data, config, suffixUrl, params}) {
    return this.fetchApi({url, data, config, method: 'put', suffixUrl, params});
  }

  delete({url, data, config, suffixUrl, params}) {
    return this.fetchApi({url, data, config, method: 'delete', suffixUrl, params});
  }
}


export const ssoAuth = new ApiClient(constants.API_CONFIG.BASE_AUTH)
export const gateway = new ApiClient(constants.API_CONFIG.BASE_GATEWAY)
export const notification = new ApiClient(constants.API_CONFIG.BASE_NOTIFICATION);
export const process = new ApiClient(constants.API_CONFIG.PROCESS_NOTIFICATION);
export const loyalty = new ApiClient(constants.API_CONFIG.BASE_LOYALTY);
export const wallet = new ApiClient(constants.API_CONFIG.BASE_WALLET);
const client = new ApiClient(constants.API_CONFIG.BASE_URL);
export default client;
