import constants from "@src/utility/constants";

function getApiPort(url) {
  let baseUrl = constants.API_CONFIG.BASE_URL;
  if (url) {
  }

  return {
    baseUrl,
    version: "",
  };
}

function getRequestUrl(url) {
  const { baseUrl, version } = getApiPort(url);
  const baseUrlWithVersion = version ? `${version}` : '';
  return `${baseUrlWithVersion}/${url}`;
}

export default getRequestUrl;
