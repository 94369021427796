// // ** Redux, Thunk & Root Reducer Imports
// import thunk from 'redux-thunk'
// import createDebounce from 'redux-debounced'
// import rootReducer from '../reducers/rootReducer'
// import { createStore, applyMiddleware, compose } from 'redux'

// // ** init middleware
// const middleware = [thunk, createDebounce()]

// // ** Dev Tools
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// // ** Create store
// const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

// export { store }

// ** Redux, Thunk & Root Reducer Imports
import rootReducer from '../reducers/rootReducer';
import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({thunk: false}).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export {store};

