import {all, call, put, takeLatest} from 'redux-saga/effects';
import {authActions} from '@store/actions/auth';
import AUTH_ACTION_TYPES from '@store/actions/auth/actionTypes';

import authService from '@services/api/service/authService';
import userService from '@services/api/service/userService';
import constants from "@constants";


const {loginSuccess, loginFailure, logoutSuccess} = authActions;

function* watchLoginAction(action) {
  try {
    const params = {
      type: constants.AUTH_TYPE.CREDENTIALS
    }
    const response = yield call(authService.loginAuth, action.payload, params);
    sessionStorage.setItem(
      constants.LOCAL_STORAGE_KEY.ACCESS_TOKEN,
      response.data.accessToken,
    );
    sessionStorage.setItem(
      constants.LOCAL_STORAGE_KEY.REFRESH_TOKEN,
      response.data.refreshToken,
    );
    sessionStorage.setItem(
      constants.LOCAL_STORAGE_KEY.REFRESH_EXPIRE_IN,
      response.data.refreshExpiresIn,
    );

    const userInfo = yield call(userService.getUserInfoAuth);
    console.log('response', userInfo)

    sessionStorage.setItem(
      constants.LOCAL_STORAGE_KEY.USER_INFO,
      JSON.stringify(userInfo?.data)
    );
    yield put(loginSuccess(userInfo?.data));
    return;
  } catch (error) {
    console.log(error, 'errorerrorerrorerror');
    alert('Login fail');
    yield put(loginFailure());
  }
}

function* watchLogoutAction() {
  try {
    // localStorage.removeItem(constants.LOCAL_STORAGE_KEY.USER_INFO)
    // localStorage.removeItem(constants.LOCAL_STORAGE_KEY.ACCESS_TOKEN)
    // localStorage.removeItem(constants.LOCAL_STORAGE_KEY.REFRESH_TOKEN)
    // localStorage.removeItem(constants.LOCAL_STORAGE_KEY.REFRESH_EXPIRE_IN)
    sessionStorage.clear();
    yield put(logoutSuccess());
  } catch (error) {}
}


function* fetchWatcher() {
  yield all([
    takeLatest(AUTH_ACTION_TYPES.LOGIN_ACTION, watchLoginAction),
    takeLatest(AUTH_ACTION_TYPES.LOGOUT_ACTION, watchLogoutAction),
  ]);
}

export default fetchWatcher;
