import client, {ssoAuth} from '../client';
import listUrl from '../url/listUrl';

export default {

  getUserInfo: () => {
    return new Promise((resolve, reject) => {
      ssoAuth
        .get({
          url: listUrl.USER.user_info,
          config: {
            isAuthorization: true
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getListUser: (data) => {
    return new Promise((resolve, reject) => {
      client
        .post({
          url: listUrl.SUPER_ADMIN.list_users,
          data,
          config: {
            isAuthorization: true
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addUser: (data) => {
    return new Promise((resolve, reject) => {
      client
        .post({
          url: listUrl.SUPER_ADMIN.add_user,
          data,
          config: {
            isAuthorization: true
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateUser: (data) => {
    return client.post({
      url: listUrl.SUPER_ADMIN.update_user,
      data,
      config: {
        isAuthorization: true
      }
    });
  },

  getUserInfoAuth: () => {
    return new Promise((resolve, reject) => {
      ssoAuth
        .get({
          url: listUrl.USER.userInfo,
          config: {
            isAuthorization: true,
            isAuth: true
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
