import client, {ssoAuth} from '../client';
import listUrl from '../url/listUrl';
import SecureHelper from '@src/@core/helpers/SecureHelper';
import constants from "@constants";

export default {
  // authorizationCode: (data) => {
  //   return client.get({
  //     url: listUrl.AUTH.authorization_code,
  //     data,
  //   });
  // },

  login: (data) => {
    return new Promise((resolve, reject) => {
      ssoAuth
        .post({
          url: listUrl.AUTH.login,
          data,
          config: {
            isAuthorization: false
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loginAuth: (data, params) => {
    return new Promise((resolve, reject) => {
      ssoAuth
        .post({
          url: listUrl.AUTH.token,
          data,
          params,
          config: {
            isAuthorization: false,
            isAuth: true
          }
        })
        .then((res) => {
          SecureHelper.setPrivateKey(res.data.sk);
          sessionStorage.setItem(
            constants.LOCAL_STORAGE_KEY.SK,
            res.data.sk,
          );
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
