import * as moment from 'moment';
import constants from './constants';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => sessionStorage.getItem('userData')
export const getUserData = () => JSON.parse(sessionStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole.id) return '/dashboard'
  // if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// ** Renders Role DateTime Columns
export const renderColumnDateTime = (dateTime) => {
  const formatting = { timeStyle: "medium", dateStyle: "short" }

  return (
    <span>
      {formatDate(dateTime, formatting)}
    </span>
  )
}

export const USER_ROLE = {
  SUPERADMIN: 1
}

const ROLE_ACTION = {
  1: [
    {
      action: 'manage',
      subject: 'all'
    }
  ],
  2: [
        {
        action: 'manage',
        subject: 'all'
      }
  ],
  3: [
      {
        action: 'read',
        subject: 'ACL'
      },
      {
        action: 'read',
        subject: 'Auth'
      }
  ],
  4: [
    {
      action: 'read',
      subject: 'ACL'
    },
    {
      action: 'read',
      subject: 'Auth'
    }
  ],
  5: [
    {
      action: 'read',
      subject: 'ACL'
    },
    {
      action: 'read',
      subject: 'Auth'
    }
  ]
};

export const initRole = (role) => {
  if(Object.keys(ROLE_ACTION).includes(role.toString())) {
    return ROLE_ACTION[role]
  }
  return ROLE_ACTION[5];
}

export const formatTimestampToDate = (timestamp) => {
  return new Date(timestamp).toLocaleDateString();
}

export const msToTime = (duration) => {
  let minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;

  return hours + ":" + minutes;
}

export const checkCmsUser = (userData) => {
  return userData?.userInfoClientOutputs.find(f => f.clientId == constants.APP_CONFIG.CLIENT_ID)
}

export const formatCurrency = value => {
  const thousandSeperator = ',';
  const decimalPoint = '.';
  if (!value) {
    return `${value}`;
  }
  // if (CURRENCY_ALLOW_DECIMAL_POINT.includes(currencyUnit)) { value = parseFloat(value).toFixed(2); }
  const parts = value.toString().split(decimalPoint);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeperator);
  return `${parts.join(decimalPoint)}`;
};

export const formatCurrencyToNumber = (value) => {
  if(value) {
    return Number(value.toString().replace(/[^0-9.-]+/g,""));
  }
  return null;
}

export const removeEmptyObject = obj => {
  return Object.keys(obj)
    .filter(k => obj[k] != null && obj[k] != '' && obj[k] != NaN)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export const getListSelectAssetConfig = (data, gameId, type) => {
  const tmpData = data.filter(f => f.gameId == gameId && f.type == type);
  return ([...tmpData] || []).map(item => {
    return {
      label: item.value,
      value: item.elementId
    }
  })
}

export const getValueByObject = (data) => {
  let tmpData = {...data};
  Object.keys(data).forEach(e => {
    if(Object.keys(constants.ASSET_ELEMENT_CONFIG_NAME).includes(e) && data[e]) {
      tmpData[e] = data[e].value
    }
  })
  return tmpData;
}

export const getLabelByValue = (data, gameId, type) => {
  const tmpData = data.find(f => f.gameId == gameId && f.type == type);
  if(tmpData) {
    return tmpData?.value;
  }
}

export const getObjectByValue = (data, listElementConfig) => {
  let tmpData = {...data};
  Object.keys(data).forEach(e => {
    if(Object.keys(constants.ASSET_ELEMENT_CONFIG_NAME).includes(e) && data[e]) {
      tmpData[e] = {
        value: tmpData[e],
        label: getLabelByValue(listElementConfig, data.gameId, constants.ASSET_ELEMENT_CONFIG_NAME[e])
      }
    }
  })
  return tmpData;
}

export const getObject = (value, listData) => {
  return listData.find(f => f?.value == value);
}

export const getItemByValueName = (listData, value, name = 'id') => {
  return listData.find(f => f[name] == value);
}