const constants = {
  API_CONFIG: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASE_AUTH: process.env.REACT_APP_BASE_AUTH,
    BASE_GATEWAY: process.env.REACT_APP_BASE_GATEWAY,
    BASE_NOTIFICATION: process.env.REACT_APP_BASE_NOTIFICATION,
    PROCESS_NOTIFICATION: process.env.REACT_APP_PROCESS_NOTIFICATION,
    BASE_LOYALTY: process.env.REACT_APP_BASE_LOYALTY,
    BASE_WALLET: process.env.REACT_APP_BASE_WALLET,
  },

  APP_CONFIG: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET
  },

  LOCAL_STORAGE_KEY: {
    ACCESS_TOKEN: 'token',
    REFRESH_TOKEN: 'refreshToken',
    REFRESH_EXPIRE_IN: 'refreshExpiresIn',
    USER_INFO: 'userData',
    SK: 'sk',

    CATALOG_KEY: {
      LIST_ROLES: "listRoles",
      LIST_HERO_TYPES: "listHeroTypes"
    }
  },

  FIREBASE_CONFIG: {
    API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    MSG_SENDER_ID: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
    FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
    MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID,
  },

  ROLE: {
    SUPER_ADMIN: 1
  },

  REACT_APP_PRIVATE_KEY: process.env.REACT_APP_PRIVATE_KEY,

  USER_AUTH: {
    CLIENT_ID: "blockchain_gateway",
    CLIENT_SECRET: "8bdbc8d8-2395-4a7c-a7ce-3e397bd8fa5c",
  },

  AUTH_TYPE: {
    CREDENTIALS: 'CREDENTIALS',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
  },

  STATUS: {
    SUCCESS: 0
  },

  LIST_COIN_ADDRESS: [
    {
      value: 'MTF',
      label: 'MTF',
      ownerAddress: '0xc408b7cce2ca711ca0e92c7ce8e2edabca0a7356'
    }
  ],

  ACTION_TYPE: {
    DETAIL: 'DETAIL',
    LISTING: 'LISTING'
  },

  TRANFER_MENU: {
    general: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      username: 'johndoe',
      fullName: 'John Doe',
      email: 'granger007@hogward.com',
      company: 'IBM Technology'
    },
  },

  STATUS_OBJ: {
    pending: 'light-warning',
    active: 'light-success',
    inactive: 'light-secondary'
  },

  CONTRACT_ADDRESS: '0xc408b7cce2ca711ca0e92c7ce8e2edabca0a7356',

  STATUS_NFT: [
    {
      value: false,
      label: 'Not Listing'
    },
    {
      value: true,
      label: 'Listing'
    }
  ],

  LIST_STATUS: [
    {
        value: 'ACTIVE',
        label: 'Active'
    },
    {
        value: 'INACTIVE',
        label: 'Inactive'
    }
  ],

  NETWORK_LISTS: [
    {
      value: 'BSC',
      label: 'BSC',
    },
    {
      value: 'ETH',
      label: 'ETH',
    },
    {
      value: 'SOLANA',
      label: 'SOLANA',
    },
    {
      value: 'AVALANCHE',
      label: 'AVALANCHE',
    },
    {
      value: 'POLYGON',
      label: 'POLYGON',
    },
  ],

  ASSET_ELEMENT_CONFIG_NAME: {
    heroClass: 'CLASS',
    // element: 'ELEMENT',
    race: 'RACE',
    rarity: 'RARITY',
    attackType: 'ATTACK_TYPE',
    fuelType: 'FUEL_TYPE',
    idName: 'NAME'
  },

  ASSET_STATUS_LIST: [
    {
      label: "NONE",
      value: "NONE"
    },
    {
      label: "WAIT_MINT",
      value: "WAIT_MINT"
    },
    {
      label: "MINTED",
      value: "MINTED"
    },
    {
      label: "SALE",
      value: "SALE"
    },
    {
      label: "WAIT_SALE_LISTING",
      value: "WAIT_SALE_LISTING"
    },
    {
      label: "WAIT_UN_SALE_LISTING",
      value: "WAIT_UN_SALE_LISTING"
    },
    {
      label: "WAIT_RENT_LISTING",
      value: "WAIT_RENT_LISTING"
    },
    {
      label: "WAIT_UN_RENT_LISTING",
      value: "WAIT_UN_RENT_LISTING"
    },
    {
      label: "FOR_RENT",
      value: "FOR_RENT"
    },
    {
      label: "ACTIVE_IN_GAME",
      value: "ACTIVE_IN_GAME"
    },
    {
      label: "BURN",
      value: "BURN"
    }
  ],

  ASSET_TYPE: [
    {
      label: 'Hero',
      value: 'HERO',
    },
    {
      label: 'PET',
      value: 'PET',
    },
    {
      label: 'Land',
      value: 'LAND',
    },
    {
      label: 'Farm',
      value: 'FARM',
    },
    {
      label: 'Transform Card',
      value: 'TRANSFORM_CARD',
    },
    {
      label: 'Hero Shard',
      value: 'HERO_SHARD',
    },
    {
      label: 'Items',
      value: 'ITEMS',
    },
    {
      label: 'Bundles',
      value: 'BUNDLES',
    },
  ],
  MINT: [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ],
  ASSET_MYSTERY_BOX_TYPE: [
     {
      label: 'Mystery Box',
      value: 'MYSTERY_BOX',
    },
  ],
  ASSET_CONSUMABLE_TYPE: [
    {
      label: 'Tournament Ticket',
      value: 'TOURNAMENT_TICKET',
    },
    {
      label: 'Transform Farm Card',
      value: 'TRANSFORM_FARM_CARD',
    },
    {
      label: 'Transform Hero Card',
      value: 'TRANSFORM_HERO_CARD',
    },
  ],
  NOTIFICATION_CODE: [
    {
      label: 'System',
      value: 'SYSTEM',
    },
    {
      label: 'Trade Notification',
      value: 'TRADE_NOTIFICATION',
    },
    {
      label: 'Activities',
      value: 'ACTIVITIES',
    },
    {
      label: 'New',
      value: 'NEW',
    },
  ],
  GENDER: [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
  ]
};
export default constants;
