import SecureHelper from '@src/@core/helpers/SecureHelper';

const API_ERROR_MESSAGE = {
  CLIENT_ERROR: 'Bad request!',
  SERVER_ERROR: 'Server error!',
  TIMEOUT_ERROR: 'No response received!',
  CONNECTION_ERROR: 'Server not available, bad dns!',
  NETWORK_ERROR: 'Network not available!',
  CANCEL_ERROR: 'Request timeout!',
};

const PROBLEM_MESSAGE = {
  CLIENT_ERROR: API_ERROR_MESSAGE.CLIENT_ERROR,
  SERVER_ERROR: API_ERROR_MESSAGE.SERVER_ERROR,
  TIMEOUT_ERROR: API_ERROR_MESSAGE.TIMEOUT_ERROR,
  CONNECTION_ERROR: API_ERROR_MESSAGE.CONNECTION_ERROR,
  NETWORK_ERROR: API_ERROR_MESSAGE.NETWORK_ERROR,
  CANCEL_ERROR: API_ERROR_MESSAGE.CANCEL_ERROR,
};

export const transformRequest = (request) => {
  if (!request.headers['isAuthorization']) {
    request.headers['Authorization'] = undefined;
  }
  // console.log('REQUEST_BEFORE_ENCRYPT');
  // console.log(request);
  // console.log(JSON.stringify(request.data), 'REQUEST_DATA');
  // console.log('----------------------');
};

export const transformError = (response) => {
  if (response.ok && response.data.encrypt) {
    // const encryptedText = response.data.data;
    // const decryptedData = AESProvider.decryptApiAuth(encryptedText);
    // response.data.data = decryptedData ? JSON.parse(decryptedData) : null;
    response.data.data = JSON.parse(
      SecureHelper.decryptWithServerAuth(response.data.data),
    );
    // console.log('RESPONSE_DATA');
    // console.log(response.data.data, 'DECRYPTED_RESPONSE')
    // console.log('------------------------')
  }

  if (response.problem || !response.ok) {
    if (
      response.problem === 'CANCEL_ERROR' &&
      response.originalError.message === 'manual'
    ) {
      response.message = '';
    } else if (PROBLEM_MESSAGE[response.problem]) {
      response.message = PROBLEM_MESSAGE[response.problem];
    } else {
      response.message = 'Có lỗi ngoại lệ xảy ra. Vui lòng thử lại!';
    }
  }
};
