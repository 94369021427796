export default {
  AUTH: {
    login: 'auth/v1/login',
    register: 'auth/v1/register',
    token: 'public/auth/token'
  },
  SUPER_ADMIN: {
    add_user: 'superadmin/add-user',
    list_users: 'users',
    update_user: 'user'
  },
  USER: {
    user_info: 'user/v1/userinfo',
    userInfo: 'userinfo',
    listUsers: 'users',
    list_user: 'users',
    add_user: 'user',
    update_user: 'user',
    update_status: 'user',
    unlock_user: 'user',
    USER: 'user',
    login_history: 'user/login-history'
  },
  CATALOG: {
    list_roles: 'superadmin/roles',
    list_type_heros: 'superadmin/blockchain/type-heros'
  },
  BLOCK_CHAIN: {
    mint_token: 'asset-hero/mint',
    list_mint_request: 'superadmin/blockchain/list-mint-request',
    list_pool: 'superadmin/blockchain/list-pool',
    add_pool: 'superadmin/blockchain/add-pool',
    while_list_pool: 'superadmin/blockchain/while-list-pool',
    add_listing: 'asset/sale-listing',
    un_listing: 'asset/sale-unlisting',
    transfer_token: 'asset/transfer-token',
    transfer_nft: 'asset/transfer-nft',
    detail_token: 'superadmin/blockchain/token',
    tokens: 'superadmin/blockchain/tokens',
    transfer_coin: 'superadmin/blockchain/transfer-ttc',
    cryptocurrency_price: 'auth/v1/cryptocurrency-price/last-info',
    cryptocurrency_price_search: 'public/v1/cryptocurrency-price/search',
    list_heros: 'superadmin/blockchain/heros',
    gen_hero: 'superadmin/blockchain/gen-hero',
    update_token: 'superadmin/blockchain/update-token',
    add_minter: 'superadmin/blockchain/add-minter',
    change_locker: 'superadmin/blockchain/change-locker',
    set_approval_for_all: 'superadmin/blockchain/set-approval-for-all',
    set_while_list_market_place: 'superadmin/blockchain/set-while-list-market-place',
    set_while_list_rental_storage: 'superadmin/blockchain/set-while-list-rental-storage',
    update_hero: 'superadmin/blockchain/update-hero',
    gen_asset_hero: 'asset-hero',
    update_gen_asset_hero: 'asset-hero',
    asset_hero_mint: 'asset-hero/mint',
    asset_hero_groups: 'asset-hero-groups',
    asset_hero_groups_tokens: 'asset-hero-groups',
    currencies_crypto: 'currencies/crypto',
    asset_heroes: 'asset-heroes',
    set_hot_item:'set-hot-asset',
    remove_hot_item:'remove-hot-items',
  },
  GAME: {
    games_list: 'games',
    add_game: 'game',
    update_game: 'game',
    detail_game: 'games',
    servers_list: 'game-servers',
    add_server: 'game-servers',
    update_server: 'game-servers',
    get_all_game: 'admin/game/get-all',
    game_properties_list: 'game-properties',
    game_properties_add: 'game-properties',
    game_properties_update: 'game-properties',
    game_characters: 'game-characters'
  },
  CAMPAIGN: {
    campaigns_list: 'admin/campaign/get-all',
    add_campaign: 'admin/campaign/insert-or-update',
    update_campaign: 'admin/campaign/insert-or-update',
    ads_channels_list: 'public/ads-channel/get-list',
    country_list: 'public/country/get-list',
    get_one: 'admin/campaign/get-one',
    delete: 'admin/campaign/delete',
    search: 'admin/campaign/search'
  },
  EVENT: {
    event_list: 'admin/event/search',
    add_event: 'admin/event/insert',
    update_event: 'admin/event/update',
    ads_channels_list: 'public/ads-channel/get-list',
    country_list: 'public/country/get-list',
    get_one: 'admin/event/get-one',
    delete: 'admin/event/delete'
  },
  LOG_ACTION: {
    get_all_action: 'admin/action/get-all',
    get_detail_action: 'admin/action/action-detail'
  },
  MANAGER_CATALOG: {
    ads_channel_insert: 'public/ads-channel/insert',
    ads_channel_update: 'public/ads-channel/update',
    ads_channel_delete: 'public/ads-channel/delete',
    country_insert: 'public/country/insert',
    country_update: 'public/country/update',
    country_delete: 'public/country/delete'
  },
  SCHEDULER: {
    scheduler_job_list:'schedule',
    create: 'schedule/create',
    update: 'schedule/update',
    start_now: 'schedule',
    pause: 'schedule',
    resume: 'schedule',
    delete: 'schedule',
    log: 'schedule'
  },
  BANNER: {
    search: 'banners',
    insertOrUpdate: 'banner',
    get_one: 'admin/banner/get-one',
    delete: 'admin/banner/delete'
  },
  UPLOAD_FILE: {
    upload_file: 'resource/upload',
    upload_multiple_files: 'admin/upload/uploadMultipleFiles'
  },
  CLIENT: {
    list_client: 'clients',
    client: 'client',
    list_user_assigned: 'user/assign-client',
    list_user: 'user/unassign-client'
  },
  ROLE: {
    list_role: 'roles',
    role: 'role'
  },
  RESOURCE: {
    upload_file: 'admin/upload/uploadFile',
    upload_multiple_files: 'admin/upload/uploadMultipleFiles'
  },
  FEED: {
    list_feeds: 'feeds',
    insertFeed: 'feed',
    updateFeed: 'feed',
    deleteFeed: 'feed',
    forbidden: 'forbidden-word',
    list_comment: 'feed',
  },
  EMAIL: {
    list_emails: 'email-templates',
    insert_email: 'email-template',
    update_email: 'email-template',
    delete_email: 'email-template',
  },
  PACKAGE_CONFIG: {
    list_package_configs: 'package-configs',
    insert_package_config: 'package-config',
    update_package_config: 'package-config',
    delete_package_config: 'package-config',
  },
  CURRENCY_CONFIG: {
    list_configs: 'currencies',
    insert_config: 'currencies',
    update_config: 'currencies'
  },
  SITE_CONFIG: {
    get_config: 'site-config',
    update_config: 'site-config'
  },
  ASSET_MANAGER: {
    get_list: 'asset-element',
    insert: 'asset-element',
    edit: 'asset-element',
    delete: 'asset-element',
    element_config: 'public/asset/element-config'
  },
  SKILL_MANAGER: {
    get_list: 'asset-skill',
    insert: 'asset-skill',
    edit: 'asset-skill',
    delete: 'asset-skill',
  },
  GENERIC_NOTIFICATION_MANAGER: {
    get_list: 'generic-notifications',
    insert: 'generic-notification',
    edit: 'generic-notification',
    delete: 'generic-notification',
  },
  COLLECTIONS_MANAGER: {
    get_list: 'collections',
    edit: 'collection',
  },
  MYSTERY_BOX: {
    mystery_box: 'asset/mystery-box',
    mystery_box_sale: 'public/asset/mystery-box',
  },
  ASSET_CONSUMABLE: {
    asset_consumable: 'asset-consumable'
  },
  DAILY_CHECKIN_CONFIG: {
    get_config: 'daily-checkin-config',
    update_config: 'daily-checkin-config'
  },
  WATCH_ADS_REWARD_CONFIG: {
    get_config: 'watch-ads-reward-config',
    update_config: 'watch-ads-reward-config'
  },
  REF_REWARD_CONFIG: {
    get_config: 'ref-reward-config',
    update_config: 'ref-reward-config'
  },
  VIP_CONFIG: {
    get_config: 'vip-config',
    update_config: 'vip-config'
  },
  APP_VERSION: {
    get_list: 'app-version',
    create_new: 'app-version',
    update_version: 'app-version',
  },
  VOUCHER_CONFIG: {
    create: 'vouchers',
    get_list: 'vouchers',
    delete_voucher:'vouchers'
  },
  ADMIN: {
    balances: 'admin/balances',
    balances_histories: 'admin/balance-histories',
    balance_loyalty: 'admin/balance-loyalty',
    balance_loyalty_histories: 'admin/balance-loyalty/histories',
    current_ref_quest: 'admin/current-ref-quest',
    ref_quest_histories: 'admin/ref-quest/histories',
    current_vip: 'admin/current-vip',
    vip_histories: 'admin/vip/histories',
    summary_ref_bonus_info: 'admin/summary-ref-bonus-info',
    top_up_histories: 'top-up-histories'
  },
  DASHBOARD: {
    deposit_channel: 'dashboard/deposit-channel',
    deposit: 'dashboard/deposit'
  },

  CATEGORY: {
    getlist: 'mini-app/categories',
    insert: 'mini-app/category',
    update: 'mini-app/category',
    get_one: 'mini-app/category',
    delete: 'mini-app/category'
  },
  HOSTAPP: {
    getlist: 'mini-app/host-app',
    insert: 'mini-app/host-app',
    update: 'mini-app/host-app',
    get_one: 'mini-app/host-app',
    delete: 'mini-app/host-app'
  },
  MINIAPP: {
    getlist: 'mini-app',
    insert: 'mini-app',
    update: 'mini-app',
    get_one: 'mini-app',
    delete: 'mini-app'
  },
  PARTNER: {
    getlist: 'mini-app/partner',
    insert: 'mini-app/partner',
    update: 'mini-app/partner',
    get_one: 'mini-app/partner',
    delete: 'mini-app/partner'
  },
  HOME_MINIAPP: {
    getlist: 'mini-app/home',
    insert: 'mini-app/home',
    update: 'mini-app/home',
    get_one: 'mini-app/home',
    delete: 'mini-app/home'
  },
  WIDGET: {
    getlist: 'mini-app/widgets',
    insert: 'mini-app/widgets',
    update: 'mini-app/widgets',
    get_one: 'mini-app/widgets',
    delete: 'mini-app/widgets'
  },
  THEME: {
    getlist: 'mini-app/theme',
    insert: 'mini-app/theme',
    update: 'mini-app/theme',
    get_one: 'mini-app/theme',
    delete: 'mini-app/theme'
  }
  
};
